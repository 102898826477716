import NewsTickerBanner from './index';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { adIcon } from '../../icons';
import useGetCurrentCommunity from '../../hooks/useGetCurrentCommunity';

export const Banners = () => {
  const communityCheck = useGetCurrentCommunity();
  const { community } = communityCheck;
  return community ? (
    <>
      <SubscriptionBanner communityId={community.communityId} />
      <AdBanner communityId={community.communityId} />
    </>
  ) : (
    <></>
  );
};

const SubscriptionBanner = ({ communityId }: { communityId: string }) => {
  const [showNewsTickerBanner, setShowNewsTickerBanner] = useState(true);
  const navigate = useNavigate();
  return (
    <>
      {showNewsTickerBanner && (
        <NewsTickerBanner
          content={
            <p style={{ cursor: 'default' }}>
              Collab.Land{' '}
              <span
                onClick={() => {
                  navigate(`/dashboard/${communityId}/subscription`);
                }}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                data-tracking-info={JSON.stringify({
                  id: 'news-ticker:subscription:click',
                  server_id: communityId,
                })}
              >
                Subscriptions
              </span>{' '}
              are here -- make your choice today!
            </p>
          }
          close={() => setShowNewsTickerBanner(false)}
        />
      )}
    </>
  );
};

const AdBanner = ({ communityId }: { communityId: string }) => {
  const [showAdBanner, setShowAdBanner] = useState(true);
  return (
    <>
      {showAdBanner && (
        <NewsTickerBanner
          bgColor={'#d1e7ff'}
          content={
            <div
              onClick={() => {
                window.open('https://defensivedefi.com');
              }}
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#23075b',
                gap: 10,
                fontWeight: 400,
              }}
              data-tracking-info={JSON.stringify({
                id: 'ad-banner:defensivedefi:click',
                server_id: communityId,
              })}
            >
              <img
                style={{
                  width: '60px',
                  height: '60px',
                }}
                src={adIcon}
                alt={'ad icon'}
              />
              <span style={{ fontWeight: 700 }}>
                Check out this free DeFi survival guide,
              </span>{' '}
              courtesy of your accounting friends at Cipher Counts
            </div>
          }
          close={() => setShowAdBanner(false)}
        />
      )}
    </>
  );
};
