import React, { Suspense, useEffect, useRef, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useSelector } from 'store/hooks';

import EmptyServerPage from 'components/EmptyServerPage';
import ServerNavigation from 'components/ServerNavigation';
import TpcConfig from 'components/TpcConfig';
import EditTpc from 'components/EditTpc';
import OverviewPage from 'components/OverviewPage';
import CommunityRoles from 'components/CommunityRoles';
import BotConfig from 'components/BotConfig';
import CreateTpc from 'components/CreateTpc';
import RoleConfig from 'components/RoleConfig';
import ValidateCommunity from 'components/ValidateCommunity';
import NotFound from 'components/NotFound';
import ServerTopBar from 'components/ServerTopBar';
import useScreenSize from 'hooks/useScreenSize';
import Overlay from 'components/Overlay';
import Toast from 'components/Toasts/Toasts';
import MarketplaceContainer from 'components/MarketplaceContainer';
import Spinner from 'components/Spinner';
import WelcomeModal from 'components/WelcomeModal';
import {
  enableProBotFeatures,
  enableProBotFeaturesV1,
  enableProBotFeaturesV2,
} from 'constants/features';

import s from './DashboardScreen.module.scss';
import MiniAppProtection from '../../components/MiniAppProtection/MiniAppProtection';
import { MINIAPP_KEYS } from '../../constants/marketplace';
import PremiumSupport from '../../components/PremuimSupport';
import usePlanHook from '../../hooks/usePlanHook';
import TgaConfig from 'components/TgaConfig';
import { ServerPage } from 'screens/ServerPage/ServerPage';
import useGetCurrentCommunity from '../../hooks/useGetCurrentCommunity';
import { alphabeticalSort } from '../../utils';

const MarketplacePage = React.lazy(() => import('components/MarketplacePage'));
const ProductPage = React.lazy(() => import('components/ProductPage'));
const GuestPassConfig = React.lazy(() => import('components/GuestPassConfig'));
const ProSubscription = React.lazy(() => import('components/ProSubscription'));
const ReleaseNotes = React.lazy(() => import('components/ReleaseNotes'));
const GuestPassCreate = React.lazy(() => import('components/GuestPassCreate'));
const GuestPassEdit = React.lazy(() => import('components/GuestPassEdit'));

const RoleCompositionPage = React.lazy(
  () => import('components/RoleCompositionPage'),
);
const RoleCompositionForm = React.lazy(
  () => import('components/RoleCompositionPage/RoleCompositionForm'),
);

const SnapshotConfiguration = React.lazy(
  () => import('components/SnapshotConfiguration'),
);

const TippingsConfiguration = React.lazy(
  () => import('components/TippingsConfiguration'),
);
const AnalyticsPage = React.lazy(
  () => import('screens/AnalyticsPage/AnalyticsPage'),
);

const DashboardRoutes: React.FC = () => {
  const rootElementRef = useRef(null);
  const [navigationVisible, setNavigationVisible] = useState(false);
  const screenSize = useScreenSize();
  const isMobileScreen = ['mobile', 'tablet'].includes(screenSize);
  const isMenuVisible = !isMobileScreen || navigationVisible;
  const navigate = useNavigate();
  const location = useLocation();
  const communityCheck = useGetCurrentCommunity();
  const { communityNotLoaded: isCommunityLoaded } = communityCheck;
  const { defaultPlanIfActive } = usePlanHook(communityCheck);

  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );

  const platform = useSelector((state) => state.user.platform);
  const isDiscord = platform === 'discord';
  useEffect(() => {
    // collapse menus on location change or on navigation.
    setNavigationVisible(false);

    // RE to account for the optional trailing slash
    const dashboardHomeRE = /^\/dashboard\/?$/;

    if (
      dashboardHomeRE.test(location.pathname) &&
      isCommunityLoaded &&
      communitiesFromState?.length > 0
    ) {
      if (defaultPlanIfActive) {
        navigate(
          `/dashboard/${
            alphabeticalSort(communitiesFromState, 'name')[0].communityId
          }/subscription`,
          {
            replace: true,
          },
        );
      } else {
        if (isDiscord) {
          navigate(
            `/dashboard/${
              alphabeticalSort(communitiesFromState, 'name')[0].communityId
            }/tgrs`,
            {
              replace: true,
            },
          );
        } else {
          navigate(
            `/dashboard/${
              alphabeticalSort(communitiesFromState, 'name')[0].communityId
            }/tgas`,
            {
              replace: true,
            },
          );
        }
      }
    }
  }, [isCommunityLoaded, communitiesFromState, navigate, location, isDiscord]);

  // @ts-ignore
  return (
    <>
      <Routes>
        <Route
          element={
            <div className={s.root} ref={rootElementRef}>
              {isMobileScreen && (
                <ServerTopBar
                  onClick={() => setNavigationVisible((prev) => !prev)}
                />
              )}

              <ServerNavigation visible={isMenuVisible} />

              <ServerPage visible={isMenuVisible} />

              <Overlay
                visible={isMobileScreen && navigationVisible}
                onClick={() => isMenuVisible && setNavigationVisible(false)}
              />
              <Toast direction="bottomRight" />
              {isCommunityLoaded && <WelcomeModal />}
            </div>
          }
        >
          <Route index element={<EmptyServerPage />} />
          <Route path=":communityId" element={<ValidateCommunity />}>
            <Route
              index
              element={
                <Navigate
                  to={{ ...location, pathname: isDiscord ? 'tgrs' : 'tgas' }}
                />
              }
            />
            {isDiscord && (
              <>
                <Route path="rolesmanager" element={<CommunityRoles />} />
                <Route path="overview" element={<OverviewPage />} />
                <Route path="tgrs/:roleId/edit/:tpcId" element={<EditTpc />} />
                <Route path="tgrs/:roleId/add" element={<CreateTpc />} />
                <Route path="tgrs/:roleId" element={<RoleConfig />} />
                <Route path="tgrs" element={<TpcConfig />} />
                {/*<Route*/}
                {/*  path="tipping"*/}
                {/*  element={<MiniAppProtection miniapp={MINIAPP_KEYS.TIPPING} />}*/}
                {/*>*/}
                {/*  <Route*/}
                {/*    index*/}
                {/*    element={*/}
                {/*      <Suspense fallback={<Spinner fullPage />}>*/}
                {/*        <TippingsConfiguration />*/}
                {/*      </Suspense>*/}
                {/*    }*/}
                {/*  />*/}
                {/*</Route>*/}
                <Route
                  path="guest-passes"
                  element={
                    <MiniAppProtection miniapp={MINIAPP_KEYS.GUEST_PASS} />
                  }
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<Spinner fullPage />}>
                        <GuestPassConfig />
                      </Suspense>
                    }
                  />

                  <Route
                    path="add"
                    element={
                      <Suspense fallback={<Spinner fullPage />}>
                        <GuestPassCreate />
                      </Suspense>
                    }
                  />
                  <Route
                    path="edit/:passId"
                    element={
                      <Suspense fallback={<Spinner fullPage />}>
                        <GuestPassEdit />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="snapshot"
                  element={
                    <MiniAppProtection miniapp={MINIAPP_KEYS.SNAPSHOT} />
                  }
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<Spinner fullPage />}>
                        <SnapshotConfiguration />
                      </Suspense>
                    }
                  />
                </Route>

                <Route
                  path="role-composition"
                  element={
                    <MiniAppProtection
                      miniapp={MINIAPP_KEYS.ROLE_COMPOSITION}
                    />
                  }
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<Spinner fullPage />}>
                        <RoleCompositionPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":roleId/add"
                    element={
                      <Suspense fallback={<Spinner fullPage />}>
                        <RoleCompositionForm />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":roleId/edit/:tpcId"
                    element={
                      <Suspense fallback={<Spinner fullPage />}>
                        <RoleCompositionForm />
                      </Suspense>
                    }
                  />
                </Route>

                {enableProBotFeaturesV2 && (
                  <>
                    <Route
                      path="analytics"
                      element={
                        <MiniAppProtection
                          miniapp={MINIAPP_KEYS.COMMUNITY_ANALYTICS}
                        />
                      }
                    >
                      <Route
                        index
                        element={
                          <Suspense fallback={<Spinner fullPage />}>
                            <AnalyticsPage />
                          </Suspense>
                        }
                      />
                    </Route>
                  </>
                )}
              </>
            )}
            {enableProBotFeaturesV1 &&
              ['discord', 'telegram'].includes(platform) && (
                <>
                  <Route
                    path="subscription"
                    element={
                      <Suspense fallback={<Spinner fullPage />}>
                        <ProSubscription />
                      </Suspense>
                    }
                  />
                </>
              )}
            {enableProBotFeatures && (
              <>
                <Route path="premium-support" element={<PremiumSupport />} />
              </>
            )}
            <Route path="config" element={<BotConfig />} />
            {platform === 'telegram' && (
              <>
                {/*<Route path="tipping">*/}
                {/*  <Route*/}
                {/*    index*/}
                {/*    element={*/}
                {/*      <Suspense fallback={<Spinner fullPage />}>*/}
                {/*        <TippingsConfiguration />*/}
                {/*      </Suspense>*/}
                {/*    }*/}
                {/*  />*/}
                {/*</Route>*/}
                <Route path="tgas/edit/:tpcId" element={<EditTpc />} />
                <Route path="tgas/add" element={<CreateTpc />} />
                <Route path="tgas" element={<TgaConfig />} />
              </>
            )}
            {isDiscord && (
              <Route path="marketplace" element={<MarketplaceContainer />}>
                <Route
                  index
                  element={
                    <Suspense fallback={<Spinner fullPage />}>
                      <MarketplacePage />
                    </Suspense>
                  }
                />
                <Route
                  path=":appId"
                  element={
                    <Suspense fallback={<Spinner fullPage />}>
                      <ProductPage />
                    </Suspense>
                  }
                />
              </Route>
            )}

            {/* Adding a second Release Notes route that is nested under the selected 
            communityId to avoid disabling the Side Menu when a user navigates to the
            release notes page while he has a community selected */}
            <Route
              path="release-notes"
              element={
                <Suspense fallback={<Spinner fullPage />}>
                  <ReleaseNotes />
                </Suspense>
              }
            />

            {/* ARCHIVED RESERVED_NAMES */}
            {/* <Route path="reserved" element={<ReservedNames />} /> */}
            {/* <Route path="banned" element={<BannedNames />} */}
            {/* ARCHIVED RESERVED_NAMES */}
          </Route>
          <Route
            path="release-notes"
            element={
              <Suspense fallback={<Spinner fullPage />}>
                <ReleaseNotes />
              </Suspense>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default DashboardRoutes;
