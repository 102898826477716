import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import RedditConnectModal from 'components/RedditConnectModal';
import Spinner from 'components/Spinner';
import s from './ServerPage.module.scss';
import useGetInfo from '../../hooks/useGetInfo';
import { Banners } from '../../components/NewsTickerBanner/Banners';
import { SideMenu } from '../../components/SideMenu/SideMenu';

interface ServerPageInterface {
  visible?: boolean;
}

export const ServerPage: React.FC<ServerPageInterface> = ({
  visible = true,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { community: activeCommunity, communityNotLoaded } = useGetInfo();
  const location = useLocation();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [contentRef, location.pathname]);

  if (!communityNotLoaded) return <Spinner fullPage />;

  return (
    <main className={s.root}>
      <div className={cx(s.main, !visible && s.hidden)}>
        <RedditConnectModal />
        <SideMenu />
      </div>
      <div className={s.content} ref={contentRef}>
        <Banners />
        <Outlet />
      </div>
    </main>
  );
};
